<template>
  <v-main class="login">
    <v-layout style="height: 100%" justify-center>
      <div class="login-cont">
        <v-layout column justify-center>
          <v-container class="text-center">
            <div class="d-flex align-center justify-center mx-auto">
              <LogoOtome style="width: 210px" />
            </div>
            <div class="login-header">
              <!-- <figure class="login-header__logo">
                <v-img :src="logo" contain></v-img>
              </figure> -->
              <p class="mt-12 login-header__catch">
                {{ $t('smart_reservation_system') }}
              </p>
              <div class="px-6 py-5 login-header__desc">
                <ul text-left>
                  <li>
                    <span>{{ $t('can_see_status') }}</span>
                  </li>
                  <li>
                    <span>{{ $t('appointment_in_20_minutes') }}</span>
                  </li>
                  <li>
                    <span>{{ $t('no_fee_card_payments') }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="login-container">
              <div
                class="col-xs-12 col-sm-4 col-sm-offset-4 mx-auto py-6 px-10 login-box"
              >
                <v-btn
                  block
                  color="primary"
                  @click="authenticate()"
                  style="font-weight: bold"
                  elevation="0"
                  height="45"
                  rounded
                >
                  <v-icon class="pr-2">mdi-lock</v-icon>{{ $t('login') }}
                </v-btn>
                <v-btn
                  block
                  outlined
                  @click="toHome()"
                  class="mt-5"
                  style="
                    font-weight: bold;
                    color: #b22c27;
                    background-color: rgba(255, 255, 255, 0.5);
                  "
                  elevation="0"
                  height="45"
                  rounded
                >
                  {{ $t('use_without_login') }}
                </v-btn>

                <div class="mt-5">
                  <span class="py-2 sub-header" style="color: #171c61">{{
                    $t('first_time_visitor')
                  }}</span>
                  <v-btn
                    block
                    outlined
                    @click="authenticateNewRegistration()"
                    style="font-weight: bold; color: #171c61"
                    elevation="0"
                    height="45"
                    rounded
                  >
                    {{ $t('register') }}
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="pt-6 explanation-banner">
              <figure>
                <a href="https://lp.tokyoescort-otome.com/lp/" target="_blank">
                  <v-img :src="explanationBanner" contain eager ></v-img>
                </a>
              </figure>
            </div>
          </v-container>
        </v-layout>
      </div>
      <!-- </v-img> -->
    </v-layout>
  </v-main>
</template>

<script>
import '~/assets/css/login.scss';
import logo from '~/assets/img/logo_white_group.svg';
import explanationBanner from '~/assets/img/learnmore2-bn.png'
import LogoOtome from '~/static/logo_otome.svg';

export default {
  auth: 'guest',
  components: {
    LogoOtome,
  },
  data: () => ({
    logo: logo,
    explanationBanner: explanationBanner,
    // ▼アフィリエイト▼
    fpcValue: '', // アフィリエイトアド用
    //bbidValue: '', //バナーブリッジ用
    // ▲アフィリエイト▲
  }),
  created() {
    this.$resetServiceWorker();
  },
  mounted() {
    // ▼アフィリエイト▼
    this.loadAffiliateScript(); //アフィリエイトアド用
    this.getUrlParameter();
    // ▲アフィリエイト▲
  },
  methods: {
    authenticate() {
      this.$auth.loginWith('auth0');
    },
    authenticateNewRegistration() {
      this.$auth.loginWith('auth0', { params: { screen_hint: 'signup', fpc: this.fpcValue } }); // アフィリエイトアドのみ用
      //this.$auth.loginWith('auth0', { params: { screen_hint: 'signup', fpc: this.fpcValue, bbid: this.bbidValue } }); //バナーブリッジも含む用
    },
    toHome() {
      this.$router.push(`/${this.$i18n.locale}`);
    },
    // ▼アフィリエイト▼
    getUrlParameter() {
      const urlParams = new URLSearchParams(window.location.search);
      this.fpcValue = urlParams.get('fpc'); // アフィリエイトアド用
      //this.bbidValue = urlParams.get('bbid'); //バナーブリッジ用
    },
    //アフィリエイトアド用
    loadAffiliateScript() {
      var uqid = "934af76e11f23b7e";
      var gid  = "2";
      var a=document.createElement("script");
      a.dataset.uqid=uqid;a.dataset.gid=gid;a.id="afadfpc-934af76e11f23b7egid1-"+Date.now();
      a.src="//ac.yumeoto.net/fpc/cookie_js.php?scriptId="+encodeURIComponent(a.id);
      document.head.appendChild(a);
    },
    // ▲アフィリエイト▲
  },
};
</script>

<style scoped>
/* v-mainは移動しないこと */
.v-main {
  height: 100% !important;
  margin-bottom: 0 !important;
}
.sub-header {
  display: flex;
  align-items: center;
  color: #fff;
}
.sub-header:before,
.sub-header:after {
  border-top: 1px solid;
  content: '';
  flex-grow: 1;
}
.sub-header:before {
  margin-right: 1rem;
}
.sub-header:after {
  margin-left: 1rem;
}
.explanation-banner {
  width: 86%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
</style>
